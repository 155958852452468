import { InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useProductQuery } from '@services/shopify';
import { useDebounce, useHasMounted, useLocalStorage } from '@utils/hooks';
import {
	ALL_BASE_FRAME_HANDLES,
	BASE_FRAME_NAMES,
	DEFAULT_BASE_FRAME_SHAPE,
	PRODUCT_TYPES,
	TOAST,
	TOP_FRAMES_PATH,
} from '@constants';
import { useToastContext } from '@context';
import useAlgoliaTracking from '@services/analytics/useAlgoliaTracking';
import { NormalizedVariant } from '@ts/product';
import { reduceBaseFrame } from '@utils/shopify';
import { trackProductView } from '@services/analytics/trackers';
import {
	AboutValueSkeleton,
	Carousel,
	CarouselSkeleton,
	ErrorBoundary,
	ProductView,
	RecommendationCarouselSkeleton,
} from '@components';
import { isNullish } from '@utils/objects';
import { getStaticProps } from '@pages/top-frames/[collection]/[handle]';
import TopSidebar from '../TopSidebar';
import styles from './TopPDP.module.scss';

const AboutValueProp = dynamic(() => import('@components').then(mod => mod.AboutValueProp));
const LicensingInfoArea = dynamic(() => import('@components').then(mod => mod.LicensingInfoArea));
const RecommendationCarousel = dynamic(() => import('@components').then(mod => mod.RecommendationCarousel));
const YotpoReviews = dynamic(() => import('@components').then(mod => mod.YotpoReviews));

const TopPDP = ({
	baseFrameVariantsAvailable,
	frameSwatches,
	licensingData,
	product,
	productTags,
	video,
	yotpo,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
	const isTopPlpAltVideo = useFeatureIsOn('is-top-pdp-alt-video');
	const { query } = useRouter();
	const hasMounted = useHasMounted();
	const [activeFrame, setActiveFrame] = useLocalStorage<(typeof BASE_FRAME_NAMES)[number]>('activeFrame', 'Larkin');
	const [activeFrameColor, setActiveFrameColor] = useLocalStorage('activeFrameColor', 'Black');
	const { showToast } = useToastContext();
	const [frameShape, setFrameShape] = useState<(typeof BASE_FRAME_NAMES)[number]>(activeFrame);
	const [selectedImgIndex, setSelectedImgIndex] = useState(0);
	const sendViewEventToAlgolia = useAlgoliaTracking();
	const {
		name,
		type,
		metafields: { alternateVideo },
	} = product;
	const isTopFrameBundle = type.includes('BUNDLE');
	const videoToUse = isTopPlpAltVideo ? (alternateVideo ?? video) : video;

	const activeBaseFrameHandle =
		Object.entries(ALL_BASE_FRAME_HANDLES).find(([handle, frame]) => frame === frameShape && handle)[0] ?? null;

	const { data: variant, isLoading }: { data: NormalizedVariant; isLoading: boolean } = useProductQuery(
		product.handle,
		{ selectedOptions: [{ name: 'Frame', value: frameShape }], includeSpecificFrameVariant: true },
		{
			queryKey: 'top-frame-variants',
			queryRefreshVars: [frameShape, product.handle],
		}
	);

	const { data: baseFrameData, isLoading: isBaseFrameVariantLoading } = useProductQuery(
		activeBaseFrameHandle,
		{ includeDescription: false, skipCollections: true, skipVariants: true },
		{
			queryKey: `base-frame-variant-${frameShape}`,
			queryRefreshVars: [activeBaseFrameHandle, frameShape],
		}
	);
	const baseFrame = useMemo(
		() => (baseFrameData ? reduceBaseFrame(baseFrameData, activeFrameColor) : null),
		[activeFrameColor, baseFrameData]
	);

	const topFrameImages = useMemo(() => {
		if (variant) {
			try {
				const varImgs = product.variantImages[variant.option.toUpperCase()];
				if (varImgs.length < 1) throw new Error();
				return varImgs;
			} catch (error) {
				// issue wih alt tag grouping
				const variantImage = [variant.image];
				return variantImage;
			}
		} else return [];
	}, [product.variantImages, variant]);

	const debouncedTrackProductView = useDebounce(trackProductView, 4000);
	const debouncedAlgoliaView = useDebounce(sendViewEventToAlgolia, 4000);
	useEffect(() => {
		if (!variant) return;
		debouncedAlgoliaView(variant.id);
		debouncedTrackProductView({
			variant,
			path: `${TOP_FRAMES_PATH}/${query.collection}`,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variant]);

	useEffect(() => {
		if (!baseFrameVariantsAvailable.includes(frameShape)) {
			showToast(TOAST.VARIANT_UNAVAILABLE);
			const isAvailabeForDefault = baseFrameVariantsAvailable.includes(DEFAULT_BASE_FRAME_SHAPE);
			const defaultFrame = isAvailabeForDefault ? DEFAULT_BASE_FRAME_SHAPE : (baseFrameVariantsAvailable[0] ?? null);
			setFrameShape(defaultFrame);
			setActiveFrame(defaultFrame);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseFrameVariantsAvailable, frameShape]);

	if (!hasMounted) return null;

	let secondaryImages;

	if (!isBaseFrameVariantLoading && !!baseFrame) {
		secondaryImages = !!product.metafields.lifestyleImage
			? [...baseFrame.color, product.metafields.lifestyleImage]
			: baseFrame.color;
	}

	return (
		<>
			<ProductView.Root
				seo={{
					product: {
						...product,
						aggregateRating: yotpo,
						images: !isLoading ? [variant?.image] : [],
						suggestedGender: 'Unisex',
						variants: !isLoading ? [variant] : [],
					},
				}}
			>
				<ProductView.Images>
					<ErrorBoundary skeleton={<CarouselSkeleton />}>
						<Carousel
							className={styles['carousel']}
							images={topFrameImages}
							isImageLoading={isLoading}
							isSecondaryImageLoading={isBaseFrameVariantLoading}
							name={name}
							secondary={!isTopFrameBundle && secondaryImages}
							selectedImgIndex={selectedImgIndex}
							setSelectedImgIndex={setSelectedImgIndex}
							type={type}
							video={videoToUse}
							variant={!isLoading ? variant : null}
						/>
					</ErrorBoundary>
				</ProductView.Images>
				<TopSidebar
					product={product}
					productTags={productTags}
					yotpo={yotpo}
					setFrameShape={setFrameShape}
					baseFrameVariantsAvailable={baseFrameVariantsAvailable}
					frameSwatches={frameSwatches}
					frameShape={frameShape}
					activeFrameColor={activeFrameColor}
					handleActiveFrameColorChange={setActiveFrameColor}
				/>
				<ProductView.Details>
					{licensingData && !isNullish(licensingData) && <LicensingInfoArea {...licensingData} />}
					<ErrorBoundary skeleton={<AboutValueSkeleton />}>
						<AboutValueProp heading='Why You’ll Love Our Top Frames' type={PRODUCT_TYPES.TOP_FRAME} />
					</ErrorBoundary>
				</ProductView.Details>
				<ErrorBoundary skeleton={<RecommendationCarouselSkeleton />}>
					<ProductView.Recommendations>
						<RecommendationCarousel
							variant={!isLoading ? variant : null}
							selectedFrame={frameShape}
							headingText={`Similar to ${name}`}
							dataTags={{
								button: { 'data-add-to-cart-from-recommendation': true },
								zoom: {},
								favorite: { 'data-add-favorite-from-recommendation': true },
							}}
						/>
					</ProductView.Recommendations>
				</ErrorBoundary>
				<ErrorBoundary>
					<ProductView.Reviews>
						<YotpoReviews product={product} />
					</ProductView.Reviews>
				</ErrorBoundary>
			</ProductView.Root>
		</>
	);
};

export default TopPDP;
